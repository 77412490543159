<template>
  <!-- Dashboard / Stats / Members. / Scores / Account & subscription => only account tab -->
  <div class="main-side-menu">
    <Menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed && !isSm"
    >
      <div v-if="isSm" class="bg-bg text-text flex items-center justify-between px-3 py-3">
        <img src="@/assets/logo.png" alt="Royal logo" class="h-10 w-" />

        <span
          class="text-lg cursor-pointer opacity-70 hover:opacity-100"
          @click="this.closeSideDrawer"
        >
          <PhX weight="bold" />
        </span>
      </div>
      <menu-item key="/dashboard" @click="() => goTo('/dashboard')">
        <template #icon>
          <PhHouse weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">Dashboard</span>
      </menu-item>

      <sub-menu :key="subMenuKeys[0]">
        <template #icon>
          <PhUsers weight="duotone" size="18" />
        </template>
        <template #title>Admins</template>
        <menu-item key="/dashboard/admins" @click="() => goTo('/dashboard/admins')"
          >Admins</menu-item
        >

        <!-- <menu-item key="/dashboard/roles" @click="() => goTo('/dashboard/roles')">Roles</menu-item> -->
      </sub-menu>

       <sub-menu :key="subMenuKeys[1]">
        <template #icon>
          <PhBookBookmark  weight="duotone" size="18" />
        </template>
        <template #title >Library</template>
         <menu-item key="/dashboard/university" @click="() => goTo('/dashboard/university')"
          >University</menu-item
        >
        <menu-item key="/dashboard/category" @click="() => goTo('/dashboard/category')"
          >Category</menu-item
        >

        <menu-item key="/dashboard/books" @click="() => goTo('/dashboard/books')"
          >Books</menu-item
        >
      </sub-menu>
      <menu-item key="/dashboard/testimonial" @click="() => goTo('/dashboard/testimonial')">
        <template #icon>
          <PhHandFist  weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">Testimonial</span>
      </menu-item>
       <menu-item key="/dashboard/blogs" @click="() => goTo('/dashboard/blogs')">
        <template #icon>
          <PhNewspaper  weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">Blogs</span>
      </menu-item>
        <sub-menu :key="subMenuKeys[2]">
        <template #icon>
          <PhSelectionAll  weight="duotone" size="18" />
        </template>
        <template #title >Sections</template>
         <menu-item key="/dashboard/library_blocks" @click="() => goTo('/dashboard/library_blocks')"
          >Library Blocks</menu-item
        >
        <menu-item key="/dashboard/smart_reader" @click="() => goTo('/dashboard/smart_reader')"
          >Smart Reader</menu-item
        >
         <menu-item key="/dashboard/ai" @click="() => goTo('/dashboard/ai')"
          >AI</menu-item
        >
         <menu-item key="/dashboard/educational_institutions" @click="() => goTo('/dashboard/educational_institutions')"
          >Educational Institutions</menu-item
        >
      </sub-menu>

       <sub-menu :key="subMenuKeys[5]">
        <template #icon>
          <PhSelectionAll  weight="duotone" size="18" />
        </template>
        <template #title >Clients</template>
         <menu-item key="/dashboard/clients" @click="() => goTo('/dashboard/clients')"
          >Clients</menu-item
        >
      </sub-menu>

       <menu-item key="/dashboard/mail_list" @click="() => goTo('/dashboard/mail_list')">
        <template #icon>
          <PhPaperPlaneTilt  weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">MailList</span>
      </menu-item>

       <menu-item key="/dashboard/inbox" @click="() => goTo('/dashboard/inbox')">
        <template #icon>
          <PhEnvelopeOpen weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">Inbox</span>
      </menu-item>

       <menu-item key="/dashboard/about_us" @click="() => goTo('/dashboard/about_us')">
        <template #icon>
          <PhPackage  weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">About Us</span>
      </menu-item>

       <menu-item key="/dashboard/setting" @click="() => goTo('/dashboard/setting')">
        <template #icon>
          <PhPackage  weight="duotone" size="18" />
        </template>
        <span class="inline-block w-36">Setting</span>
      </menu-item>

      <menu-item key="/dashboard/logs" @click="() => goTo('/dashboard/logs')">
        <template #icon>
          <PhActivity weight="duotone" size="18" />
        </template>
        <span>Logs</span>
      </menu-item>

    </Menu>
  </div>
</template>

<script>
import { Menu, MenuItem, SubMenu } from 'ant-design-vue';
import {
  PhHouse,
  PhX,
  PhUsers,
  PhActivity,
  PhPackage,
  PhEnvelopeOpen,
  PhPaperPlaneTilt,
  PhBookBookmark,
  PhHandFist,
  PhNewspaper,
  PhSelectionAll,
} from 'phosphor-vue';
import { mapMutations } from 'vuex';
import { LAYOUT } from '../../../store/types';

export default {
  components: {
    Menu,
    SubMenu,
    MenuItem,
    PhPackage,
    PhHouse,
    PhX,
    PhUsers,
    PhActivity,
    PhEnvelopeOpen,
    PhPaperPlaneTilt,
    PhBookBookmark,
    PhHandFist,
    PhNewspaper,
    PhSelectionAll,
  },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
    isSm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedKeys: [],
      openKeys: [],
      // preOpenKeys: ['sub1'],
      subMenuKeys: [
        '/dashboard/users,/dashboard/roles',
        '/dashboard/category,/dashboard/books,/dashboard/university,/dashboard/books/add_book',
        '/dashboard/educational_institutions,/dashboard/smart_reader,/dashboard/library_blocks,/dashboard/ai',
        '/dashboard/setting,/dashboard/about_us,/dashboard/slider,/dashboard/partners,/dashboard/faqs',
        '/dashboard/clients',
      ],
    };
  },

  mounted() {
    const pathname = this.$route.path;
    this.selectedKeys = [pathname];
    this.setOpenKeys(pathname);
  },
  watch: {
    $route: {
      handler(val) {
        if (val && val.path) {
          this.selectedKeys = [val.path];
          this.setOpenKeys(val.path);
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations([LAYOUT.SET_TOGGOLE_SIDEMENU]),
    setOpenKeys(val) {
      const openKeys = [];
      this.subMenuKeys.forEach((item) => {
        const subItems = item.split(',') || [];
        if (subItems.includes(val)) {
          openKeys.push(item);
        }
      });
      this.openKeys = openKeys;
    },
    goTo(url) {
      this.$router.push(url);
      this.SET_TOGGOLE_SIDEMENU(false);
    },
    goToSubTab(urls) {
      this.$router.push(urls.split(',')[0]);
      this.SET_TOGGOLE_SIDEMENU(false);
    },
    closeSideDrawer() {
      this.SET_TOGGOLE_SIDEMENU(false);
    },
  },
};
</script>

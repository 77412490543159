import { BOOK } from '../types';

export default {
  state: {
    bookQuery: null,
    languageQuery: null,
  },
  getters: {},
  mutations: {
    [BOOK.SET_BOOK_QUERY](state, payload) {
      state.bookQuery = payload;
    },
    [BOOK.SET_LANGUAGE_QUERY](state, payload) {
      state.languageQuery = payload;
    },

  },
  actions: {},
};

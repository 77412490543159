import { CLIENTS } from '../types';

export default {
  state: {
    clientQuery: null,
  },
  getters: {},
  mutations: {
    [CLIENTS.SET_CLIENTS_QUERY](state, payload) {
      state.clientQuery = payload;
    },

  },
  actions: {},
};
